.App {
  text-align: center;
}

.main_header {
}

.MuiTypography-root,
.MuiDialogActions-root,
.MuiTypography-root,
.MuiDataGrid-root {
  font-size: 1rem !important;
  font-family: "Proxima Nova", Verdana, "Proxima Nova" !important;
}

.css-rtrcn9-MuiTablePagination-root {
  color: #e4067e !important;
}

.MuiTablePagination-selectLabel {
  color: #e4067e !important;
}

.confirmation-content {
  align-content: center;
}

body {
  overflow: hidden;
  padding-bottom: 7%;
}


.p-breadcrumb {
  overflow-x: auto;
  height: 41px !important;
  padding-left: 24px;
}

.card-container {
  font-family: "Proxima Nova", Verdana !important;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 0.2rem;
  padding: 0.1rem;
}

.card-title:hover {
  box-shadow: 0 8px 16px 0 #e4067e;
}
.card-title-header{
  font-size: 1rem !important;
  font-weight: bold;
}

@media (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .card-container {
    grid-template-columns: 1fr;
  }
}

.wrap-text {
  white-space: normal !important;
}

.MuiDataGrid-columnHeader {
  background-color: #f7f7fa !important;
  line-height: 1.2em !important;
  color: #342b60;
  height: 0.75em;
  font-weight: bold;
}
.dataTable .p-datatable-tbody td {
  font-size: 0.75rem;
  color: #342b60;
}
.p-component {
  font-size: 0.75rem;
  color: #342b60;
}

.labelAsterix::after {
  content: "*";
  color: red;
  margin-left: 5px;
}

article .MuiDataGrid-root .MuiDataGrid-cell .MuiAppbar-root {
  font-size: 0.875rem !important;
  font-family: "Proxima Nova", Verdana !important;
}

h2 {
  font-weight: 900;
  font-size: 32px;
  line-height: 1;
  margin: 0 0 4px;
  display: inline-block;
  vertical-align: top;
  color: #342b60;
}

p {
  margin: 1em 0;
}

ul {
  padding-left: 30px;
  margin: 1em 0;
}

li {
  margin-bottom: 8px;
}

/*mobile cell wrap*/
.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.Mui-selected {
  background: #dadae4 !important;
}
.data_grid .wrap-text {
  border: 0 !important;
}

.no-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}


.pdf-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}