.storybook-button {
  font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
}
.storybook-button--primary {
  color: white;
  background-color: #e4067e;
  transition-duration: 0.4s;
}
.storybook-button--primary:hover {
  background: #b20563;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  color: white;
}
.storybook-button--secondary {
  color: #333;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
  transition-duration: 0.4s;
  border: 2px solid #342b60;
  border-radius: 25px;
}
.storybook-button--secondary:hover {
  background-color: #9396b0;
  color: #342b60;
}
.storybook-button--xsmall {
  font-size: 8px;
  padding: 8px 14px;
}
.storybook-button--small {
  font-size: 12px;
  padding: 10px 16px;
}
.storybook-button--medium {
  font-size: 14px;
  padding: 11px 20px;
}
.storybook-button--large {
  font-size: 16px;
  padding: 12px 24px;
}
