header {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
h3{
  color: #e4067e;
}

.wrapper-white {
  background: #ffffff;
  height: 41px !important;
  color: #342c61;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100% !important;
  align-items: center;
  justify-content: space-between;
}

.div-green {
  background-color: #62bb46;
  /*position: absolute;*/
  width: 100px;
  height: 41px;
  left: 0 !important;
  top: 0 !important;
  font-family: "Proxima Nova", Verdana, serif;
  color: #ffffff;
  justify-content: center;
}

.left-logos {
  display: flex;
  gap: 0;
  width: 250px;
}

.div-eu {
  width: 120px;
  height: 63px;
  padding: 1px 2px 2px 2px;
  justify-content: center;
}

.img-eu {
  width: 116px;
  margin:2px 0;
  height: 61px;
  justify-content: center !important;
}

.img-ministry {
  width: 118px;
  height: auto;
  padding-top:0.2em;
  justify-content: center;
}

.div-ministry {
  width: 120px;
  height: auto;
  background-color: #ffffff;
  margin:3px 0 3px 16px;
  justify-content: center!important;
}

@media (prefers-color-scheme: dark) {
  .div-ministry {
    background-color: #ffffff;
  }
}

.top_right{
  padding-left: 16px;
  flex: 1;
}

.wrapper-white{
  flex: 1;
}
.wrapper-white{
  flex: none;
  margin-left: auto;
}

.right-section {
  display: flex;
  align-items: flex-end;
  margin-right: 24px;
  margin-top: 10px;
  flex-basis: 5%;
}

@media (max-width: 768px) {
  .right-section {
    margin-right: 12px;
    margin-top: 5px;
    width: 100%;
    justify-content: flex-end;
  }
}

@media (max-width: 480px) {
  .right-section {
    margin-right: 8px;
    margin-top: 5px;
    width: 100%;
    justify-content: flex-end;
  }
}


.left-section{
  display: flex;
  align-items: flex-start;
  margin-left: auto;
}

.p-green {
  background-color: #62bb46;
  position: relative;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  margin: 13px 10px 22px 20px;
  display: flex;
  align-items: center;
}
.wrapper-white:after {
  position: absolute;
  width: 0.974em;
  height: 0.422em;
  background: #342c61;
  transform: rotate(-180deg);
}
.top-white {
  color: #342c61 !important;
}

#pointer {
  position: absolute;
  top: 40px;
  left: 2%;
  transform: translateX(100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #62bb46;
}

.wrapper {
  font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #342c61;
  padding: 15px 20px;
  display: flex;
  color: #ffffff !important;
  align-items: center;
  height: 64px !important;
  max-width: 100% !important;
  width: 100% !important;
  left: 0;
  margin-left: 0;
  margin-right: 0;
}

.right-section-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

svg {
  display: inline-block;
  vertical-align: top;
}

img{
  width: 90px;
}

.img_partners{
  height: 100%;
}

h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  margin: 6px 0 6px 10px;
  display: inline-block;
  vertical-align: top;
}

button + button {
  margin-left: 10px;
}

.welcome {
  color: white;
  font-size: 14px;
  margin-right: 10px;
}

.link_left{
  float:right;
  margin-left: auto;
  color:#000000!important;
  font-size: 1rem;
  font-weight: 400;
}

.link_left.active{
  color: #4dbed2 !important;
}

.link_left:hover{
  color: #e4067e !important;
}

.alert_right{
  float:right;
}

.language-switcher {
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
  height: 2rem;
  width: 2.125rem;
  font-size: 1rem;
  font-weight: 700;
  padding: 0 .5rem;
  color: #000000 !important;
}

.language-switcher.active {
  background-color: transparent;
  color: #4dbed2;
}

.language-switcher a {
  text-decoration: none;
  color: black;
  margin: 0 10px;
}

.hamburger-menu {
  display: none;
  position: relative;
}

.hamburger-button {
  font-size: 24px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #342c61;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-menu .menu-item {
  padding: 12px 16px;
  cursor: pointer;
}

.dropdown-menu .menu-item:hover {
  background-color: #f1f1f1;
}

/* Show hamburger menu on small screens */
@media (max-width: 768px) {
  .right-section-bottom {
    display: none;
  }
  .hamburger-menu {
    margin-left: auto;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .dropdown-menu {
    display: block;
  }
}