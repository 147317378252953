body {
  display: grid;
  height: 100vh;
  max-width: 100% !important;
  grid-template-rows: auto 1fr auto;
}

.footer_home {
  width: 100% !important;
  position: fixed;
  bottom: 0;
  height: 7%;
  color: #ffffff;
  background: linear-gradient(to right, #dadae4 0.8%, rgba(98, 187, 70, 0.4) 100%),
  linear-gradient(to right, rgba(52, 44, 97, 0.5) 0%, rgba(52, 44, 97, 0) 100%),
  radial-gradient(circle at 24.1% 11.12%, rgba(151, 133, 255, 0.2) 0%, rgba(98, 187, 70, 0.2) 100%);
}

@media (max-width: 768px) {
  .footer_home {
    height: 5%;
  }
}

@media (max-width: 480px) {
  .footer_home {
    height: 4%;
  }
}

.subheading {
  --mediumdark: "#999999";
  font-weight: 900;
  font-size: 13px;
  color: #999;
  letter-spacing: 6px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 12px;
  margin-top: 40px;
}

.link-list {
  display: grid;
  grid-template-columns: 1fr; /* Single column by default */
  row-gap: 10px;
}

@media (min-width: 620px) {
  .link-list {
    grid-template-columns: 1fr 1fr; /* Two columns for larger screens */
    row-gap: 20px;
    column-gap: 20px;
  }
}

@media all and (-ms-high-contrast: none) {
  .link-list {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 1fr 1fr;
  }

  @media (min-width: 620px) {
    .link-list {
      -ms-grid-columns: 1fr 1fr;
    }
  }
}

.link-list-right {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  row-gap: 0.2em !important;
  width: 25vw;
  margin-left: 10px;
}

@media (min-width: 210px) {
  .link-list-right {
    row-gap: 0.2em !important;
    column-gap: 10px;
    grid-template-columns: 1fr;
    width: 25vw;
    margin-left: 10px;
  }
}

@media all and (-ms-high-contrast: none) {
  .link-list-right {
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 1fr 1fr;
    width: 25vw;
    margin-left: 10px;
  }
}

a {
  text-decoration: none;
  color: #1ea7fd;
}
.link-item-right img {
  height: 30px;
  width: 30px;
  margin-right: 0.625rem;
}
.link-item img {
  height: 40px;
  width: 40px;
  margin-right: 0.625rem;
}
.link-item span {
  font-size: 14px;
  align: center;
  line-height: 20px;
}

.left {
  flex: 1 1 25% !important;
  justify-content: left !important;
}
.right {
  justify-content: right !important;
  flex: 1 1 75% !important;
}

.link-item {
  display: flex;
  padding: 0.625rem;
  border: 1px solid #00000010;
  border-radius: 5px;
  transition:
          background 150ms ease-out,
          border 150ms ease-out,
          transform 150ms ease-out;
  color: #333333 !important;
  align-items: flex-start !important;
  /*white-space: nowrap;*/
  font-size: 1rem;
}
@media (max-width: 600px) {
  .link-item {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .link-item {
    font-size: 0.75rem;
  }
}

.link-item:hover {
  border-color: #e4067e !important;
  transform: translate3d(0, -3px, 0);
  box-shadow: rgba(0, 0, 0, 0.08) 0 3px 10px 0;
}

.link-item:active {
  border-color: #e05fa5 !important;
  transform: translate3d(0, 0, 0);
}

.link-item strong {
  font-weight: 700;
  display: block;
  text-align: left;
  margin-bottom: 2px;
  color: black;
}

.tip {
  display: inline-block;
  border-radius: 1em;
  font-size: 11px !important;
  line-height: 12px;
  font-weight: 700;
  background: #1b4e84 !important;
  color: #ffffff !important;
  padding: 4px 12px;
  margin-right: 10px !important;
  vertical-align: top;
}

.tip-wrapper {
  font-size: 13px !important;
  line-height: 20px !important;
  margin-top: 20px !important;
  margin-bottom: 40px;
  margin-left: 10px;
  display: flex;
  max-width: 60% !important;
  align-items: flex-start;
}

.tip-wrapper code {
  font-size: 12px;
  display: inline-block;
}

.home_article {
  display: flex !important;
  flex-wrap: wrap;
  max-width: 100% !important;
  width: 100% !important;
  height: 82%;
  overflow-y: auto;
  padding-bottom: 7%;
  margin-bottom: 7%;
}

.home_article::-webkit-scrollbar {
  width: 6px;
}

.home_article::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.home_article::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

.home_article::-webkit-scrollbar-thumb:hover {
  background: #9396b0;
}

@media (max-width: 619px) {
  .home_article {
    display: flex !important;
    flex-direction: column;
    overflow-x: auto;
    overflow-y:auto;
    height: calc(100vh - 7%);
  }

  .link-list, .link-list-right {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .home_article {
    display: flex !important;
    flex-direction: column;
    overflow-x: auto;
    overflow-y:auto;
    height: calc(100vh - 5%);
  }
}

.box_home{
  height: 100vh;
  width: 100%;
}

.section_left {
  float: left;
  width: 70% !important;
  max-width: 70%;
  max-height:100%;
  height: 100% !important;
  margin: 1em 0 1em 2em;
}

.section_right {
  float: right;
  width: 20%;
  max-width: 20%;
  height: 100% !important;
  padding-right: 1em;
  margin: 0.2em 1em 0 1em;
}

@media (max-width: 768px) {
  .section_right {
    float: none;
    width:70% !important;
    max-width: 70%;
    margin: 1em 0 1em 2em;
  }
}

.title_updates {
  --mediumdark: "#999999";
  font-weight: 900;
  font-size: 13px;
  color: #999;
  letter-spacing: 6px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 2.5em 0 1em 0.75em;
}

/* For screens wider than 600px */
@media screen and (min-width: 601px) {
  .tip-wrapper {
    font-size: 1vw;
  }
}

/* For screens 600px wide or less */
@media screen and (max-width: 600px) {
  .tip-wrapper {
    font-size: 0.4vw;
  }
}

.footer_div{
  width: 100%;
  background-color:#007bff;
  margin: 3rem;
}

.right-icon {
  width: 20px!important;
  height: 20px!important;
  vertical-align: middle;
}

